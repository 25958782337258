<template>
    <div class="modal">
        <div class="modal-background"></div>
        <div class="modal-card largura">
            <header class="modal-card-head">
                <p class="modal-card-title">{{titulo}}</p>
                <button class="delete" @click="fecharModal() " aria-label="close"></button>
            </header>
            <section class="modal-card-body">
            <slot></slot>
            </section>
            <!-- <footer class="modal-card-foot ">
                <button class="button is-danger" @click="fecharModal(true)">Confirmar</button>
                <button class="button" @click="fecharModal(false)">Cancelar</button>
            </footer> -->
        </div>
      </div>
</template>

<script>
    export default {
        props: {
            titulo: String,
        },
        methods: {
            fecharModal(){
                this.$emit('modalFechado')
            }
        }
    };
</script>

<style scoped lang="scss">
  .largura{
    width: 80% !important;
  }
</style>