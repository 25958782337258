<template>
  <div class="columns">
    <div class="column is-12">
      <div class="card">
        <div class="card-content">
          <p class="title is-4" v-if="!this.editando">Novo Script</p>
          <p class="title is-4" v-if="!!this.editando">Editar Script</p>
          <hr>
          <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <form class="salvar" @submit.prevent="handleSubmit(salvar)">
          <div class="columns">
            <div class="column">
                <label class="label">Produto</label>
                <div class="select is-fullwidth">
                  <select
                    disabled
                    v-model="idProdutoSelecionado"
                    @change="preencherListaVersoes()"
                  >
                    <option v-for="(produto, i) in produtos" v-bind:key="i" :value="produto.id">{{produto.nome}}</option>>
                  </select>
                </div>
            </div>
            <div class="column">
              <label class="label">Versão</label>
              <div class="select is-fullwidth">
                <select
                  disabled
                  v-model="script.idVersao"
                >
                    <option value="" v-if="(versoes.length > 0 && !script.idVersao) ||!idProdutoSelecionado" disabled selected>Selecione uma versão</option>
                    <option value="" v-if="idProdutoSelecionado && versoes.length === 0" disabled selected>Nenhuma versão encontrada para esse produto</option>
                    <option v-for="(versao, i) in versoes" v-bind:key="i" :value="versao.id">{{versao.codigo}}</option>>
                </select>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Descrição</label>
                <div class="control">
                  <ValidationProvider name="descrição" rules="required">
                    <div slot-scope="{ errors }">
                      <input v-model="script.descricao" type="text" class="input"
                        :class="{'is-danger' : errors.length}" />
                      <small class="has-text-danger">{{ errors[0] }}</small>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">Ordem</label>
                <div class="control">
                  <ValidationProvider name="ordem" rules="required">
                    <div slot-scope="{ errors }">
                      <input v-model="script.ordem" type="text" class="input"
                        :class="{'is-danger' : errors.length}" />
                      <small class="has-text-danger">{{ errors[0] }}</small>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>

          <code-mirror-script :script="script.script"></code-mirror-script>
              <footer class="field is-grouped is-grouped-right has-margin-bottom-5">
                <p class="control">
                  <button class="button is-link cor-fundo-btn has-margin-top-5" :class="{'is-loading' : loading }">
                    <span>Salvar</span>
                    <span class="icon is-small">
                      <i class="fas fa-save"></i>
                    </span>
                  </button>
                </p>
              </footer>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CodeMirrorScript from '../components/CodeMirrorScript.vue';
  import NovoScript from '@/model/script/novoScript.js'


  export default {
    components: {
      CodeMirrorScript
    },
    props: {
      dados: {
        type: Object
      }
    },
    data() {
      return {
        loading: false,
        idProdutoSelecionado: '',
        versaoSelecionada:'',
        script: new NovoScript(),
        editando: false,
      };
    },
    created() {
      this.$store.dispatch("produtos/listarTodos");
      this.preencherInputs();
    },
    computed: {
      produtos() {
        return this.$store.state.produtos.items;
      },
      versoes() {
        return this.$store.state.versoes.items;
      },
      codigo() {
        return this.$store.state.scripts.codigo;
      },
    },
    methods: {
      preencherListaVersoes(){
          this.$store.dispatch("versoes/listarTodosPorProduto", this.idProdutoSelecionado);
      },
      salvar() {
        this.loading = true;

        if (!!this.editando) {
          this.$store.dispatch("scripts/editarScript", this.script)
        } else {
          this.script.script = this.codigo
          this.$store.dispatch("scripts/criarScript", this.script)
        }

        this.loading = false;
        this.limparTela();
        this.$emit('abrirView', 'ListarScripts');
      },
      preencherInputs() {
        this.editando = (this.dados.scriptSelecionado !== undefined)

        if (!!this.editando) {
          this.script = this.dados.scriptSelecionado
        } else {
          this.limparTela()
        }
        this.idProdutoSelecionado = this.dados.produto
        this.script.idVersao = this.dados.versao
      },
      limparTela() {
        this.editando = false;
        this.script = new NovoScript();
      },
    }
  };
</script>

<style lang="scss" scoped>
</style>