<template>
    <div>
        <canvas :id="idComponente"></canvas>
    </div>
</template>

<script>
import Chart from 'chart.js';

export default {
    props :{
        tipo: String,
        colunas: Array,
        valores: Array,
        idComponente: String
    },
    data() {
        return {
            colunasEx:['A','B','C'],
            valoresData:[],
            tipoPadrao:'bar',
            chartGraph:''
        }
    },
    methods: {
    montar(tipo){
        const ctx = document.getElementById(this.idComponente);
        this.chartGraph = new Chart(ctx, {
            type: this.tipo,
            data: {
                labels: (this.colunas ? this.colunas : this.colunasEx),
                datasets: [{
                    data: this.valores,
                    label: 'Gráfico',
                    borderWidth: 3,
                    backgroundColor: [
                        '#ff6384',
                        '#a23384',
                        '#666384',
                        '#51d0f9',
                        '#ffa5ef',
                        '#baf726'
                    ],
                }]
            },
        });
    },
    atualizarValores(valores){
        this.chartGraph.data.datasets[0].data = valores;
        this.chartGraph.update();
    },
    atualizarTipo(tipo){
        // this.chartGraph.type = tipo;
        // this.chartGraph.update();
        this.montar()
    },
  },
  mounted() {
      this.montar()
  },
  watch: {
     tipo: {
        handler(){
            this.atualizarTipo(this.tipo)
        },
        deep: true
    },
    valores: {
        handler() {
            this.atualizarValores(this.valores)
        },
        deep: true
    },
  }
}
</script>