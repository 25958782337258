<template>
  <div class="columns">
    <div class="column is-12">
      <div class="card">
        <div class="card-content">
          <p class="title is-4">Executar Migração</p>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(salvar)">
              <div class="columns">
                <div class="column">
                  <div class="field">
                    <label class="label">Código Produto</label>
                    <div class="control">
                      <ValidationProvider name="código produto" rules="required">
                        <div slot-scope="{ errors }">
                          <input
                            v-model="executarMigracao.idProduto"
                            type="text"
                            class="input"
                            :class="{'is-danger' : errors.length}"
                          />
                          <small class="has-text-danger">{{ errors[0] }}</small>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="field">
                    <label class="label">Código Versão</label>
                    <div class="control">
                      <ValidationProvider name="código versão" rules="required">
                        <div slot-scope="{ errors }">
                          <input
                            v-model="executarMigracao.idVersao"
                            type="text"
                            class="input"
                            :class="{'is-danger' : errors.length}"
                          />
                          <small class="has-text-danger">{{ errors[0] }}</small>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
              <div class="is-divider has-margin-top-10"></div>
              <footer class="field is-grouped is-grouped-right has-margin-bottom-5">
                <!-- <p class="control">
                <a class="button  is-light">Cancel</a>
                </p>-->
                <p class="control">
                  <button class="button is-link cor-fundo-btn" :class="{'is-loading' : loading }">
                    <span>Executar</span>
                    <span class="icon is-small">
                      <i class="fas fa-bolt"></i>
                    </span>
                  </button>
                </p>
              </footer>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import this.$store from "@/this.$store";
import C from "@/constantes";

export default {
  data() {
    return {
      loading: false,
      executarMigracao: {}
    };
  },
  methods: {
    salvar() {
      this.loading = true;
      const auth = {
        headers: {
          authorization: "Bearer 8faa3c74-860e-4ca7e-a976-712bbe8e94b1",
          "content-type": "application/json"
        }
      };

      this.$http.get(C.URL.MIGRACAO.EXECUTAR, auth).then(
        response => {
          this.resposta = response;
          let notification = {
            exibirMensagem: true,
            tipoMensagem: "sucesso",
            textoMensagem: "Operação realizada com Sucesso."
          };
          this.$store.commit("exibirNotification", notification);
          this.loading = false;
        },
        error => {
          this.resposta = error;
          let notification = {
            exibirMensagem: true,
            tipoMensagem: true,
            textoMensagem: "Ops ! Encontramos um erro: " + error
          };
          this.$store.commit("exibirNotification", notification);
          this.loading = false;
        }
      );
    }
  }
};
</script>

<style>
</style>