import Vue from "vue";
import Vuex from "vuex";

import storeConfig from './storeConfig' 

import  notificacoes  from './notificacoes.modulo'
import  produtos  from './produtos.modulo'
import { migracoes } from './migracoes.modulo'
import  versoes  from './versao.modulo'
import  scripts  from './script.modulo'
import  databases  from './databases.modulo'
import  schemas  from './schemas.modulo'

Vue.use(Vuex);

const store = new Vuex.Store({
  ...storeConfig,
   modules: {
    notificacoes,
    produtos,
    migracoes,
    versoes,
    scripts,
    databases,
    schemas
  }
});

export default store