import { Promise } from 'core-js'
import {schemaServico} from '../services'

const schemas = {
    state: {
        items: [],
        status: {
            carregandoTodos: false
        },
    },
    actions: {
        criarSchema({rootState, commit,dispatch}, payload){
            schemaServico.criar(rootState,commit, dispatch, payload)
        },
        buscarSchemaPorDb({commit,dispatch}, payload){
            return new Promise((resolve)=>{
                schemaServico.buscarPorDb(commit, dispatch, payload).then(response =>{
                    resolve(response)
                })
            })
        },
    },
    mutations: {
        buscarSchemaPorDbSucesso(state, database) {
            state.status.carregandoTodos = false;
            state.items = database
        },
        buscarSchemaPorDbErro(state) {
            state.status.carregandoTodos = false;
            state.items = []
        },
    }
}

export default schemas