<template>
  <div class="columns">
    <div class="column is-12">
      <div class="card">
        <div class="card-content">
          <p class="title is-4">Inicializar Base</p>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(salvar)">
              <div class="columns">
                <div class="column is-7">
                  <div class="field">
                    <label class="label">Código Produto</label>
                    <div class="control">
                      <ValidationProvider name="código produto" rules="required">
                        <div slot-scope="{ errors }">
                          <input
                            v-model="inicializar.idProduto"
                            type="text"
                            class="input"
                            :class="{'is-danger' : errors.length}"
                          />
                          <small class="has-text-danger">{{ errors[0] }}</small>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="column is-5">
                  <div class="field">
                    <label class="label">Ordem</label>
                    <div class="control">
                      <ValidationProvider name="ordem" rules="numeric">
                        <div slot-scope="{ errors }">
                          <input
                            v-model="inicializar.ordem"
                            type="text"
                            class="input"
                            :class="{'is-danger' : errors.length}"
                          />
                          <small class="has-text-danger">{{ errors[0] }}</small>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <div class="field">
                    <label class="label">Descrição</label>
                    <div class="control">
                      <ValidationProvider name="descrição" rules="required">
                        <div slot-scope="{ errors }">
                          <input
                            v-model="inicializar.descricao"
                            type="text"
                            class="input"
                            :class="{'is-danger' : errors.length}"
                          />
                          <small class="has-text-danger">{{ errors[0] }}</small>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <div class="field">
                    <input
                      class="is-checkradio is-link"
                      id="estruturacheck"
                      type="checkbox"
                      name="estruturacheck"
                      checked="checked"
                      v-model="inicializar.estrutura"
                    />
                    <label for="estruturacheck">Estrutura</label>
                  </div>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <div class="field">
                    <label class="label">Script</label>
                    <div class="control">
                      <ValidationProvider name="script" rules="required">
                        <div slot-scope="{ errors }">
                          <!-- <codemirror v-model="inicializar.script" :options="cmOption"></codemirror> -->
                          <small class="has-text-danger">{{ errors[0] }}</small>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>

              <div class="is-divider has-margin-top-10"></div>

              <footer class="field is-grouped is-grouped-right has-margin-bottom-5">
                <!-- <p class="control">
                <a class="button  is-light">Cancel</a>
                </p>-->
                <p class="control">
                  <button class="button is-link cor-fundo-btn" :class="{'is-loading' : loading }">
                    <span>Iniciar</span>
                    <span class="icon is-small">
                      <i class="fas fa-play"></i>
                    </span>
                  </button>
                </p>
              </footer>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import this.$store from "@/this.$store";
import C from "@/constantes";
//codemirror
// import { codemirror } from "vue-codemirror";
// import "codemirror/lib/codemirror.css";
// import "codemirror/keymap/sublime.js";
// import "codemirror/theme/base16-light.css";
// import "codemirror/mode/sql/sql.js";
// import "codemirror/theme/base16-dark.css";
// import "codemirror/addon/selection/active-line.js";
// import "codemirror/keymap/sublime.js";
// import "codemirror/addon/fold/foldgutter.css";

export default {
  // components: { codemirror },
  data() {
    return {
      loading: false,
      inicializar: {},
      cmOption: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        foldGutter: true,
        styleSelectedText: true,
        mode: "text/x-sql",
        keyMap: "sublime",
        matchBrackets: true,
        showCursorWhenSelecting: true,
        theme: "base16-light",
        extraKeys: { Ctrl: "autocomplete" },
        hintOptions: {
          completeSingle: false
        }
      }
    };
  },
  methods: {
    salvar() {
      this.loading = true;
      const auth = {
        headers: {
          authorization: "Bearer 8faa3c74-860e-4ca7e-a976-712bbe8e94b1",
          "content-type": "application/json"
        }
      };

      this.$http.post(C.URL.MIGRACAO.INICIALIZAR, this.inicializar, auth).then(
        response => {
          this.resposta = response;
          let notification = {
            exibirMensagem: true,
            tipoMensagem: "sucesso",
            textoMensagem: "Operação realizada com Sucesso."
          };
          this.$store.commit("exibirNotification", notification);
          this.loading = false;
        },
        error => {
          this.resposta = error;
          let notification = {
            exibirMensagem: true,
            tipoMensagem: "erro",
            msg: "Ops ! Encontramos um erro: " + erro
          };
          this.$store.commit("exibirNotification", notification);
          this.loading = false;
        }
      );
    }
  }
};
</script>

<style>
</style>