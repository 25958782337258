import {
    migracoesServico
} from '../services'
import {
    catchError
} from '../helpers'

export const migracoes = {
    state: {
        acompanhamentos: [],
        acompanhamentoGrafico: []
    },
    actions: {
        buscarMigracoes({commit}, payload) {
            return new Promise((resolve, reject) => {
                migracoesServico.buscarMigracoes(payload).then(response => {
                    resolve(response)
                }, error => {
                    reject(error)
                })
            })
        },
    },
    mutations: {
        listarAcompanhamentos(state, payload) {
            state.acompanhamentos = payload
        }
    }
}