import { Promise } from 'core-js'
import {databaseServico} from '../services'

const databases = {
    state: {
        items: [],
        status: {
            carregandoTodos: false
        },
    },
    actions: {
        criarDatabase({rootState, commit,dispatch}, payload){
            return new Promise((resolve)=>{
                databaseServico.criar(rootState,commit, dispatch, payload).then(response =>{
                    resolve(response)
                })
            })
        },
        excluirDatabase({rootState, commit,dispatch}, payload){
            return new Promise((resolve)=>{
                databaseServico.excluir(rootState,commit, dispatch, payload).then(response =>{
                    resolve(response)
                })
            })
        },
    },
}

export default databases