<template>
  <div class="columns">
    <div class="column is-12">
      <div class="card">
        <div class="card-content">
          <p class="title is-4">Reinicializar Migração</p>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(salvar)">
              <div class="columns">
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Código Produto</label>
                    <div class="control">
                      <ValidationProvider name="codigo produto" rules="required">
                        <div slot-scope="{ errors }">
                          <input
                            v-model="reinicializar.idProduto"
                            type="text"
                            class="input"
                            :class="{'is-danger' : errors.length}"
                          />
                          <small class="has-text-danger">{{ errors[0] }}</small>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>

                <div class="column is-6">
                  <div class="field">
                    <label class="label">Schema</label>
                    <div class="control">
                      <ValidationProvider name="schema" rules="required">
                        <div slot-scope="{ errors }">
                          <input
                            v-model="reinicializar.schema"
                            type="text"
                            class="input"
                            :class="{'is-danger' : errors.length}"
                          />
                          <small class="has-text-danger">{{ errors[0] }}</small>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>

              <div class="is-divider has-margin-top-10"></div>

              <footer class="field is-grouped is-grouped-right has-margin-bottom-5">

                <p class="control">
                  <button class="button is-link cor-fundo-btn" :class="{'is-loading' : loading }">
                    <span>Reiniciar</span>
                    <span class="icon is-small">
                      <i class="fas fa-sync-alt"></i>
                    </span>
                  </button>
                </p>
              </footer>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import this.$store from "@/this.$store";
import C from "@/constantes";

export default {
  data() {
    return {
      loading: false,
      reinicializar: {}
    };
  },
  methods: {
    salvar() {
      this.loading = true;
      const auth = {
        headers: {
          authorization: "Bearer 8faa3c74-860e-4ca7e-a976-712bbe8e94b1",
          "content-type": "application/json"
        }
      };

      this.$http
        .post(
          C.URL.MIGRACAO.REINICIALIZAR,
          this.reinicializar,
          auth
        )
        .then(
          response => {
            this.resposta = response;
            let notification = {
              showNotification: true,
              error: false,
              msg: "Operação realizada com Sucesso."
            };
            this.$store.commit("exibirNotification", notification);
            this.loading = false;
          },
          error => {
            this.resposta = error;
            let notification = {
              showNotification: true,
              error: true,
              msg: "Ops ! Encontramos um erro..."
            };
            this.$store.commit("exibirNotification", notification);
            this.loading = false;
          }
        );
    }
  }
};
</script>

<style>
</style>