import config from 'config'

export default {
    URL: {
        MIGRACAO: {
            VERSAO: `${config.apiUrl}/versoes`,
            PRODUTO: `${config.apiUrl}/produtos`,
            EXECUTAR: `${config.apiUrl}/migracoes/migrar`,
            ACOMPANHAR: `${config.apiUrl}/acompanhamentos`,
            REFAZER: `${config.apiUrl}/migracoes/retantar-migracao`,
            BASE: {
                INICIALIZAR: `${config.apiUrl}/reinicializacoes/executar`,
                REINICIALIZAR: `${config.apiUrl}/reinicializacoes/scrips`
            }
        }
    }
}
