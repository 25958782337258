import axios from 'axios'
import config from 'config'
import {authHeader} from '../helpers'

export const versaoServico = {
    listarTodos,
    listarTodosPorProduto,
    criar,
    editar,
    desativar
}

function listarTodos(commit, dispatch) {
    let dadosModal = {
        exibirModal: true,
        textoModal: 'Realizando Consulta'
    }
   
    commit("exibirOcultarModalLoading", dadosModal, {root:true})

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return axios(`${config.apiUrl}/versoes`, requestOptions)
    .then((response) => {
      commit('listarTodosSucesso', response.data)
      return response
    })
    .catch((error) => {
      commit("exibirNotification", error, {root:true})
      commit('listarTodosErro')
      return error
    })
    .finally(() => {
      commit('exibirOcultarModalLoading', false, {root:true})
    })
}

function listarTodosPorProduto(commit, dispatch, idProduto) {
    let dadosModal = {
        exibirModal: true,
        textoModal: 'Realizando Consulta'
    }
    commit("exibirOcultarModalLoading", dadosModal, {root:true})

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return axios(`${config.apiUrl}/versoes/por-produto/` + idProduto, requestOptions)
    .then(function (response) {
      commit('listarTodosSucesso', response.data)
      return response
    })
    .catch(function (error) {
      commit("exibirNotification", error, {root:true})
      commit('listarTodosErro')
      return error
    })
    .finally(function() {
      commit('exibirOcultarModalLoading', false, {root:true})
    })
}

function criar(rootState, commit, dispatch, versao) {
  let dadosModal = {
    exibirModal: true,
    textoModal: 'Realizando Consulta'
  }
  commit("exibirOcultarModalLoading", dadosModal, {root:true})

  const requestOptions = {
    method: 'post',
    data: JSON.stringify(versao),
    headers: authHeader(),
    transformRequest: [function (data) {
      return data;
    }]
  }

  return axios(`${config.apiUrl}/versoes`, requestOptions)
    .then((response) => {
      rootState.tipoCrud = response.config.method
      commit("exibirNotification", 'sucesso', {root:true})
      return response
    })
    .catch((error) => {
      commit("exibirNotification", error, {root:true})
      return error
    })
    .finally(() => {
      commit('exibirOcultarModalLoading', false, {root:true})
    })
}

function editar(rootState, commit, dispatch, payload) {
  let versao = {
    idProduto: payload.idProduto,
    codigo: payload.codigo,
    descricao: payload.descricao,
    ativo: payload.ativo,
  }

  let dadosModal = {
    exibirModal: true,
    textoModal: 'Realizando Consulta'
  }
  commit("exibirOcultarModalLoading", dadosModal, {root:true})

  const requestOptions = {
    method: 'PUT',
    data: JSON.stringify(versao),
    headers: authHeader(),
    transformRequest: [function (data) {
      return data;
    }]
  }

  return axios(`${config.apiUrl}/versoes/` + payload.id, requestOptions)
  .then((response) => {
    rootState.tipoCrud = response.config.method
    commit("exibirNotification", "sucesso", {root:true})
    return response
  })
  .catch((error) => {
    commit("exibirNotification", error, {root:true})
    return error
  })
  .finally(() => {
    commit('exibirOcultarModalLoading', false, {root:true})
  })
}

function desativar(rootState, commit, dispatch, payload) {
  let versao = {
    idProduto: payload.produto.id,
    codigo: payload.codigo,
    descricao: payload.descricao,
    ativo: payload.ativo,
  }

  let dadosModal = {
    exibirModal: true,
    textoModal: (versao.ativo ? 'Ativando item' : 'Desativando item')
  }
  commit("exibirOcultarModalLoading", dadosModal, {root:true})

  const requestOptions = {
    method: 'PUT',
    data: JSON.stringify(versao),
    headers: authHeader(),
    transformRequest: [function (data) {
      return data;
    }]
  }

  return axios(`${config.apiUrl}/versoes/` + payload.id, requestOptions)
    .then((response) => {
      rootState.tipoCrud = response.config.method
      commit("exibirNotification", "sucesso", {root:true})
      return response
    })
    .catch( (error) => {
      commit("exibirNotification", error, {root:true})
      return error
    })
    .finally(() => {
      commit('exibirOcultarModalLoading', false, {root:true})
    })
}
