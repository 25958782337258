import axios from 'axios'
import config from 'config'
import {authHeader} from '../helpers'

export const produtoServico = {
  listarTodos,
  criar,
  editar
}


function listarTodos(commit, dispatch) {
  let dadosModal = {
    exibirModal: true,
    textoModal: 'Realizando Consulta'
  }
  commit("exibirOcultarModalLoading", dadosModal, {root:true})

  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return axios(`${config.apiUrl}/produtos?pageSize=100`, requestOptions)
    .then((response) => {
      commit('listarTodosSucesso', response.data)
      return response
    })
    .catch((error) => {
      commit('listarTodosErro')
      commit("exibirNotification", error, {root:true})
      return error
    })
    .finally(() => {
      commit('exibirOcultarModalLoading', false, {root:true})
    })
}

function criar(rootState, commit, dispatch, produto) {
  let dadosModal = {
    exibirModal: true,
    textoModal: 'Realizando Consulta'
  }
  commit("exibirOcultarModalLoading", dadosModal, {root:true})

  const requestOptions = {
    method: 'POST',
    data: JSON.stringify(produto),
    headers: authHeader(),
    transformRequest: [function (data) {
      return data;
    }]
  }

  return axios(`${config.apiUrl}/produtos`, requestOptions)
    .then((response) => {
      rootState.tipoCrud = response.config.method
      commit("exibirNotification", "sucesso", {root:true})
      return response
    })
    .catch((error) => {
      commit("exibirNotification", error, {root:true})
      return error
    })
    .finally(() => {
      commit('exibirOcultarModalLoading', false, {root:true})
    })
}

function editar(rootState, commit, dispatch, produto) {
  let dadosModal = {
    exibirModal: true,
    textoModal: 'Realizando Consulta'
  }
  commit("exibirOcultarModalLoading", dadosModal, {root:true})

  const requestOptions = {
    method: 'PUT',
    data: JSON.stringify(produto),
    headers: authHeader(),
    transformRequest: [function(data){
      return data
    }]
  }

  return axios(`${config.apiUrl}/produtos/` + produto.id, requestOptions)
    .then((response) => {
      rootState.tipoCrud = response.config.method
      commit("exibirNotification", "sucesso", {root:true})
      return response
    })
    .catch((error) => {
      commit("exibirNotification", error, {root:true})
      return error
    })
    .finally(() => {
      commit('exibirOcultarModalLoading', false, {root:true})
    })
}