<template>
  <div class="columns">
    <div class="column is-12">
      <div class="card">
        <div class="card-content">

          <p class="title is-4">Acompanhar Migração</p>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(buscarMigracoes)">
              <div class="columns">
                <div class="column is-4">
                  <div class="columns">
                    <div class="column">
                      <div class="field">
                        <label class="label">Código Produto</label>
                        <div class="control">
                          <ValidationProvider name="código Produto" rules="required">
                            <div slot-scope="{ errors }">
                              <input v-model="dadosGet.idProduto" class="input" :class="{'is-danger' : errors.length}"
                                type="text" />
                              <small class="has-text-danger">{{ errors[0] }}</small>
                            </div>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div class="field">
                        <label class="label">Limite</label>
                        <div class="control">
                          <ValidationProvider name="limite" rules="numeric">
                            <div slot-scope="{ errors }">
                              <input v-model="dadosGet.limit" class="input" :class="{'is-danger' : errors.length}"
                                type="text" />
                              <small class="has-text-danger">{{ errors[0] }}</small>
                            </div>
                          </ValidationProvider>
                        </div>
                      </div>

                      <div class="is-divider has-margin-top-30"></div>

                      <footer class="field is-grouped is-grouped-right has-margin-bottom-5">
                        <p class="control">
                          <button class="button is-link cor-fundo-btn" :class="{'is-loading' : loading }">
                            <span>Consultar</span>
                            <span class="icon is-small">
                              <i class="fas fa-search"></i>
                            </span>
                          </button>
                        </p>
                      </footer>

                    </div>
                  </div>
                </div>

                <div class="column is-8">
                  <div class="has-text-centered" v-if="loading">
                    <Loading />
                  </div>

                  <div class="message is-default box-migracao" v-for="msg in msgRespostas" v-bind:key="msg.idMigracao">
                    <p class="has-margin-bottom-5">
                      <strong>Código da migração:</strong>
                      {{msg.idMigracao}}
                    </p>

                    <p class="has-margin-bottom-5">
                      <strong>Código do Produto:</strong>
                      {{msg.idProduto}}
                    </p>

                    <p class="has-margin-bottom-5">
                      <strong>Nome do produto:</strong>
                      {{msg.nomeProduto}}
                    </p>

                    <button class="button cor-fundo-btn" @click="mostrarGrafico('bar', msg.idMigracao)">{{(graficosBarraAbrir.includes(msg.idMigracao)? "Ocultar Gráfico de Barras": "Mostrar Gráfico de Barras")}}</button>
                    <button class="button cor-fundo-btn" @click="mostrarGrafico('pie', msg.idMigracao)">{{(graficosTortaAbrir.includes(msg.idMigracao)? "Ocultar Gráfico de Torta": "Mostrar Gráfico de Torta")}}</button>
                    <button class="button cor-fundo-btn" @click="mostrarGrafico('progress',msg.idMigracao)">{{(progressoAbrir.includes(msg.idMigracao)? "Ocultar Barra de Progresso": "Mostrar Barra de Progresso")}}</button>
                    <!-- <button class="button cor-fundo-btn" @click="mostrarGrafico(msg.idMigracao)">{{(graficosAbrir.includes(msg.idMigracao)? "Ocultar Barra de Progresso": "Mostrar Barra de Progresso")}}</button> -->
                    <!-- <button class="button cor-fundo-btn" v-if="graficosAbrir.includes(msg.idMigracao)" @click="mudarVisualizacao(msg.idMigracao)">Mudar visualização</button> -->
                    <p>
                      <ChartJS v-if="graficosBarraAbrir.includes(msg.idMigracao)"
                        :idComponente="msg.idMigracao"
                        :tipo="'bar'"
                        :colunas="['Tentativas de migração','Total','Total de Execuções','Total de execuções com erro']"
                        :valores="[msg.tentativasMigracao,msg.total,msg.totalExecucoes,msg.totalExecucoesComErro]">
                      </ChartJS>
                    </p>
                    <p>
                      <ChartJS v-if="graficosTortaAbrir.includes(msg.idMigracao)"
                        :idComponente="msg.idMigracao"
                        :tipo="'pie'"
                        :colunas="['Tentativas de migração','Total','Total de Execuções','Total de execuções com erro']"
                        :valores="[msg.tentativasMigracao,msg.total,msg.totalExecucoes,msg.totalExecucoesComErro]">
                      </ChartJS>
                    </p>

                    <p class="has-text-centered" v-if="progressoAbrir.includes(msg.idMigracao)">
                      <span >{{porcentagem(msg.totalExecucoes,msg.tentativasMigracao)}}%</span>
                      <progress class="progress is-primary" :value='msg.totalExecucoes' :max='msg.tentativasMigracao'></progress>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import this.$store from "@/this.$store";
  import Loading from "@/components/Loading";
  import C from "@/constantes";
  // import ChartJS from "@/components/ChartJS"
  import ChartJS from '../components/ChartJS.vue';

  export default {
    components: {
      Loading,
      ChartJS
    },
    data() {
      return {
        graficosBarraAbrir: [],
        graficosTortaAbrir: [],
        progressoAbrir: [],
        // graficosAbrir: [],
        loading: false,
        msgRespostas: [],
        dadosGet: {},
        dadosGrafico: {},
        colunasNome: ['a', 'b', 'c'],
        colunasValor: [1, 2, 3, 0],
        cores: [
          '#ff6384',
          '#a23384',
          '#666384'
        ],
        tipoGrafico:'bar'

      };
    },
    computed: {
      acompanhamentos: function () {
        return this.$store.state.migracoes.acompanhamentos;
      },
      erro: function () {
        return this.$store.state.notificacoes.error;
      }
    },

    methods: {
      porcentagem(valor,maximo) {
        let pct = (valor/maximo)*100
        pct = parseFloat(pct.toFixed(2))
        return pct
      },
      buscarMigracoes() {
        this.loading = true;
        this.$store.dispatch("buscarMigracoes", this.dadosGet).then(() => {
          this.msgRespostas = this.$store.state.migracoes.acompanhamentos
          this.loading = false;
        })
      },
      mostrarGrafico(tipo, id) {
        switch(tipo){
          case 'bar':
            if (this.graficosBarraAbrir.includes(id)) {
            this.graficosBarraAbrir.splice(this.graficosBarraAbrir.indexOf(id), 1)
            } else {
              this.graficosBarraAbrir.push(id)
              if (this.graficosTortaAbrir.includes(id)) {
                this.graficosTortaAbrir.splice(this.graficosTortaAbrir.indexOf(id), 1)
              }
            }
            break;
          case 'pie':
            if (this.graficosTortaAbrir.includes(id)) {
              this.graficosTortaAbrir.splice(this.graficosTortaAbrir.indexOf(id), 1)
            } else {
              this.graficosTortaAbrir.push(id)
              if (this.graficosBarraAbrir.includes(id)) {
                this.graficosBarraAbrir.splice(this.graficosBarraAbrir.indexOf(id), 1)
              }
            }
            break;
          case 'progress':
            if(this.progressoAbrir.includes(id)) {
              this.progressoAbrir.splice(this.progressoAbrir.indexOf(id), 1)
            } else {
              this.progressoAbrir.push(id)
            }
        }
      },
    },
    watch: {
      erro: {
        handler() {
          this.loading = false;

        }
      },
    }
  };
</script>

<style scoped>
  .box-migracao {
    padding: 10px;
  }
</style>