<template>
    <div class="notification-teste column is-12 is-pulled-right is-overlay">
        <article class="message is-pulled-right column is-4" :class="classeNotificacao">
            <div class="message-header">
                <p class="is-capitalized">{{this.titulo}}</p>
            </div>
            <div class="message-body">
                <p>{{this.msg}}</p>
                <!-- <p> {{ this.mensagemWebService.textoMensagem }}</p> -->
                <button class="btnCloseNotification button is-pulled-right" :class="classeNotificacao" @click="closeNotification">Ok ({{tempoInicial}})</button>
            </div>
        </article>
    </div>
</template>

<script>

export default {
  data() {
    return{
      tempoInicial:0
    };
  },
  computed: {
    error() {
      return this.$store.getters.error
    },
    msg() {
      return this.$store.getters.msg
    },
    // classeNotificacao() {
    //   return (this.$store.getters.error ? 'is-danger' : 'is-success')
    // },
    titulo() {
      return (this.error ? 'Erro' : 'Sucesso')
    }
  },
  methods: {
    closeNotification() {
      let notification = { exibirMensagem: false };
      this.$store.commit("exibirNotification", notification);
    },
    timerCloseNotification() {
      setTimeout(() => {
        this.tempoInicial--;
        if(this.tempoInicial === 0){
          return this.closeNotification()
        }
      }, 1000)
    }
  },
  mounted() {
    this.tempoInicial = 10;
    this.timerCloseNotification();
  }
};
</script>

<style scoped>
.notification-header {
  padding: 8px 15px;
  /* height: 100px; */
  /* overflow: hidden; */
  /* width: 100%; */
  transition: all 0.2s;
}

.notification-background {
  padding: 20px;
}

.notification-teste {
  z-index: 999;
}

</style>