import { render, staticRenderFns } from "./FormInserirVersao.vue?vue&type=template&id=f9978886&scoped=true&"
import script from "./FormInserirVersao.vue?vue&type=script&lang=js&"
export * from "./FormInserirVersao.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "f9978886",
  null
  ,true
)

export default component.exports