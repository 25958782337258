const storeConfig = {

    state: {
        dadosModal:{
            exibirModal: '',
            textoModal:''
          },
          tipoCrud:''
        },
    mutations: {
        exibirOcultarModalLoading(state,payload) {
            state.dadosModal.exibirModal = payload.exibirModal
            state.dadosModal.textoModal = payload.textoModal
        }
    }
}
    

export default storeConfig
