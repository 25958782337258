<template>
  <div class="columns">
    <div class="column is-12">
      <div class="card">
        <div class="card-content">
          <h2 class="title is-4">Versões</h2>
          <hr>
          <div class="columns">
            <div class="column is-9 is-pulled-left is-marginless is-paddingless has-padding-bottom-10">
              <span>Selecione um produto para listar as versões</span>
              <div class="select is-fullwidth">
                <select
                  class="btnPreencherTabela"
                  v-model="idProdutoSelecionado"
                  @change="preencherTabela()"
                >
                    <option value="" disabled selected>Selecione um produto para listar as versões cadastradas.</option>
                    <option v-for="(produto, i) in produtos" v-bind:key="i" :value="produto.id">{{produto.nome}}</option>>
                </select>
              </div>
            </div>
            <div class="column is-pulled-left btn-atualizar-Lista">
                <button type="button" class="btnAtualizarLista button is-big is-link is-pulled-right cor-fundo-btn" @click="atualizarLista()">
                <i  class="fas fa-sync has-padding-right-5" aria-hidden="true"></i>Atualizar Lista</button>
            </div>
          </div>
          <table class="table is-fullwidth" v-if="!carregando && versoes.length > 0">
            <thead>
              <tr>
                <th>Ativo</th>
                <th align="center">Código</th>
                <th align="center">Descrição</th>
                <!-- <th align="center">Id</th> -->
                <th align="right">
                    <button v-if="idProdutoSelecionado && versoes.length > 0" class="btnAbrirCadastro button is-small is-link cor-fundo-btn" @click="abrirCadastro()">Nova Versão</button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(versao , i) in versoes" v-bind:key="i">
                <td>
                  <span class="icon">
                    <i v-if="versao.ativo" class="fas fa-check has-text-success"></i>
                    <i v-if="!versao.ativo" class="fas fa-times has-error has-text-danger"></i>
                  </span>
                </td>

                <td align="center">
                  <strong>
                    <span>{{versao.codigo}}</span>
                  </strong>
                </td>

                <td align="center">
                  <strong>
                    <span>{{versao.descricao}}</span>
                  </strong>
                </td>

                <!-- <td>
                  <strong>
                    <span>{{versao.id}}</span>
                  </strong>
                </td> -->

                <td align="right">
                  <div class="dropdown is-right is-hoverable">
                    <div class="dropdown-trigger">
                      <button class="button  is-small" aria-haspopup="true" aria-controls="dropdown-menu6">
                        <span class="icon is-small">
                          <i class="fas fa-ellipsis-v" aria-hidden="true"></i>
                        </span>
                      </button>
                    </div>
                    <div class="dropdown-menu" id="dropdown-menu6" role="menu">
                      <div class="dropdown-content">
                        <a @click="abrirEdicao(versao)" class="abrirEdicao dropdown-item">
                          Editar
                        </a>
                        <a @click="excluir(versao);" class="btnExcluir dropdown-item has-text-danger">
                          <strong v-if="versao.ativo">Desativar</strong>
                          <strong v-if="!versao.ativo">Ativar</strong>
                        </a>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="!idProdutoSelecionado && versoes.length === 0" style="margin:100px">
            <div class="columns is-centered">
              <span>
                Nenhum produto selecionado. Selecione um produto para mostrar suas versões.
              </span>
            </div>
          </div>
          <div v-if="idProdutoSelecionado && versoes.length === 0" style="margin:70px">
            <div class="columns is-centered">
              <span>
                Nenhuma versão encontrada para esse produto, deseja cadastrar um novo?
              </span>
            </div>
            <div class="columns is-centered">
                <button class="btnAbrirCadastro button is-big is-link cor-fundo-btn" style="margin:10px" @click="abrirCadastro()">Nova Versão</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-confirmacao :class="showModal" :mensagem="'Tem certeza que deseja '+ mensagemConfirmacao + 'a versão ' + versaoSelecionada.descricao + '?'" @modalFechado="modalConfirmarExclusao">
    </modal-confirmacao>
  </div>

</template>

<script>
  import ModalConfirmacao from '../components/ModalConfirmacao.vue';
  
  export default {

    components: {
      ModalConfirmacao,
    },
    created() {
      this.$store.dispatch("produtos/listarTodos");
    },
    data: () => ({
      loading: false,
      showModal: '',
      confirmarExclusao: false,
      versaoSelecionada: {},
      mensagemConfirmacao: '',
      idProdutoSelecionado: ''
    }),
    watch: {
      versaoSelecionada: {
        handler() {
          this.mensagemConfirmacao = this.versaoSelecionada.ativo ? 'desativar ' : 'ativar '
        }
      }
    },
    computed: {
      produtos() {
        return this.$store.state.produtos.items;
      },
      carregando() {
        return this.$store.state.versoes.status.carregandoTodos;
      },
      versoes() {
        return this.$store.state.versoes.items;
      },
    },
    methods: {
      atualizarLista() {
        this.idProdutoSelecionado ? this.$store.dispatch("versoes/listarTodosPorProduto", this.idProdutoSelecionado) : this.$store.dispatch("versoes/listarTodos")
      },
      abrirCadastro() {
        let payload = {
          componente: 'FormInserirVersao',
          dados: {
                  produto:
                    {
                      id:this.idProdutoSelecionado
                    }
                }
        }
        this.$emit('abrirFormInserir', payload)
      },
      abrirEdicao(versaoSelecionada) {
        let payload = {
          componente: 'FormInserirVersao',
          dados: versaoSelecionada
        }
        this.$emit('abrirFormInserir', payload)
      },
      excluir(versao) {
        this.versaoSelecionada = versao
        this.modalConfirmarExclusao();
      },
      preencherTabela(){
        this.$store.dispatch("versoes/listarTodosPorProduto", this.idProdutoSelecionado);
      },
      modalConfirmarExclusao(payload) {
        //Verificar setTimeout
        if (this.showModal === '') {
          this.showModal = 'is-active';
        } else {
          this.confirmarExclusao = payload
          this.showModal = '';

          if (this.confirmarExclusao) {
            this.versaoSelecionada = {...this.versaoSelecionada, ativo:!this.versaoSelecionada.ativo}
            this.$store.dispatch("versoes/desativar", this.versaoSelecionada)
            setTimeout(() => {
                this.atualizarLista()
              }, 1000)            
          }
        }
      }
    }
  };
</script>

<style scoped>
  .btn-atualizar-Lista {
      padding-top: 23px;
  }
  .editar:hover .icon.has-text-white {
    color: #b5b5b5 !important;
  }

  .dd-edicao .dropdown-menu {
    width: 22em;
  }
</style>