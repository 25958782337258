import {produtoServico} from '../services'

const produtos = {
    namespaced: true,
    state: {
        items: [],
        status: {
            carregandoTodos: false
        },
    },
    actions: {
        listarTodos({commit,dispatch}) {
            produtoServico.listarTodos(commit,dispatch)
        },
        criar({rootState,commit,dispatch}, payload){
            produtoServico.criar(rootState,commit, dispatch, payload)
        },
        editar({rootState,commit,dispatch}, payload){
            produtoServico.editar(rootState,commit, dispatch, payload)
        },
    },
    mutations: {
        listandoTodos(state) {
            state.status.carregandoTodos = true;
            state.items = []
        },
        listarTodosSucesso(state, produtos) {
            state.status.carregandoTodos = false;
            state.items = produtos.content
        },
        listarTodosErro(state) {
            state.status.carregandoTodos = false;
            state.items = []
        },
    }
}

export default produtos