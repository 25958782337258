import axios from 'axios'
import config from 'config'
import { authHeader } from '../helpers'

export const scriptServico = {
  listarTodos,
  listarTodosPorVersao,
  criar,
  editar,
}

function listarTodos(commit, dispatch) {
    let dadosModal = {
        exibirModal: true,
        textoModal: 'Realizando Consulta'
    }
    commit("exibirOcultarModalLoading", dadosModal, {root:true})

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return axios(`${config.apiUrl}/versoes`, requestOptions)
    .then(function (response) {
      commit('listarTodosSucesso', response.data)
      return response
    })
    .catch(function (error) {
      commit("exibirNotification", error, {root:true})
      commit('listarTodosErro')
      return error
    })
    .finally(function() {
      commit('exibirOcultarModalLoading', false, {root:true})
    })
}

function listarTodosPorVersao(commit, dispatch, idVersao) {
    let dadosModal = {
        exibirModal: true,
        textoModal: 'Realizando Consulta'
    }
    commit("exibirOcultarModalLoading", dadosModal, {root:true})

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return axios(`${config.apiUrl}/scripts/por-versao/` + idVersao, requestOptions)
    .then(function (response) {
      commit('listarTodosSucesso', response.data)
      return response
    })
    .catch(function (error) {
      commit("exibirNotification", error, {root:true})
      commit('listarTodosErro')
      return error
    })
    .finally(function() {
      commit('exibirOcultarModalLoading', false, {root:true})
    })
}

function criar(rootState, commit, dispatch, payload) {
  let dadosModal = {
    exibirModal: true,
    textoModal: 'Realizando Consulta'
  }
  commit("exibirOcultarModalLoading", dadosModal, {root:true})

  const requestOptions = {
    method: 'POST',
    data: JSON.stringify(payload),
    headers: authHeader(),
    transformRequest: [function (data) {
      return data;
    }]
  }

  return axios(`${config.apiUrl}/scripts`, requestOptions)
    .then((response) => {
      rootState.tipoCrud = response.config.method
      commit("exibirNotification", "sucesso", {root:true})
      return response
    })
    .catch((error) => {
      commit("exibirNotification", error, {root:true})
      return error
    })
    .finally(() => {
      commit('exibirOcultarModalLoading', false, {root:true})
    })
}

function editar(rootState, commit, dispatch, payload) {
  let dadosModal = {
    exibirModal: true,
    textoModal: 'Realizando Consulta'
  }
  commit("exibirOcultarModalLoading", dadosModal, {root:true})

  const requestOptions = {
    method: 'PUT',
    data: JSON.stringify(payload),
    headers: authHeader(),
    transformRequest: [function (data) {
      return data;
    }]
  }

  return axios(`${config.apiUrl}/scripts/${payload.id}`, requestOptions)
    .then((response) => {
      rootState.tipoCrud = response.config.method
      commit("exibirNotification", "sucesso", {root:true})
      return response
    })
    .catch((error) => {
      commit("exibirNotification", error, {root:true})
      return error
    })
    .finally(() => {
      commit('exibirOcultarModalLoading', false, {root:true})
    })
}