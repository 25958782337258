<template>
  <div class="columns">
    <div class="column is-12">
      <div class="card">
        <div class="card-content">
          <h2 class="title is-4">Scripts</h2>
          <hr>
          <div class="columns">
            <div class="column is-pulled-left is-marginless has-margin-right-5 is-paddingless has-padding-bottom-10">
                <span>Produto</span>
                <div class="select is-fullwidth">
                  <select
                    v-model="idProdutoSelecionado"
                    @change="preencherListaVersoes()"
                  >
                      <option value="" disabled selected>Selecione um produto para listar as versões cadastradas.</option>
                      <option v-for="(produto, i) in produtos" v-bind:key="i" :value="produto.id">{{produto.nome}}</option>>
                  </select>
                </div>
            </div>
            <div class="column is-pulled-left is-marginless is-paddingless has-padding-bottom-10">
              <span>Versão</span>
              <div class="select is-fullwidth">
                <select
                :disabled="(idProdutoSelecionado && versoes.length === 0) || !idProdutoSelecionado"
                  v-model="versaoSelecionada"
                  @change="atualizarLista()"
                >
                    <option value="" v-if="(versoes.length > 0 && !versaoSelecionada) ||!idProdutoSelecionado" disabled selected>Selecione uma versão para listar os scripts</option>
                    <option value="" v-if="idProdutoSelecionado && versoes.length === 0" disabled selected>Nenhuma versão encontrada para esse produto</option>
                    <option v-for="(versao, i) in versoes" v-bind:key="i" :value="versao">{{versao.codigo}}</option>>
                </select>
              </div>
            </div>
          </div>
          <div class="columns" v-if="this.versaoSelecionada">
            <div class="column align-center">
              <h2 class="title is-4">Scripts de {{this.versaoSelecionada.produto.nome}} {{this.versaoSelecionada.codigo}}</h2>
              <p>{{this.versaoSelecionada.descricao}}</p>
            </div>
            <div class="column is-auto btn-atualizar-Lista">
                <button type="button" class="btnAtualizarLista button is-big is-link is-pulled-right cor-fundo-btn" @click="atualizarLista()">
                <i  class="fas fa-sync has-padding-right-5" aria-hidden="true"></i>Atualizar Lista</button>
            </div>
          </div>
          <hr>
          <table class="table is-fullwidth is-hoverable" v-if="!carregando && versaoSelecionada && scripts.length > 0">
            <thead>
              <tr>
                <th>Ativo</th>
                <th align="center">Descrição</th>
                <th align="center">Ordem</th>
                <th align="right">
                    <button v-if="versaoSelecionada && scripts.length > 0" class="btnAbrirCadastro button is-small is-link cor-fundo-btn" @click="abrirCadastro()">Novo script</button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(script , i) in scripts" v-bind:key="i">
                <td>
                  <span class="icon">
                    <i v-if="script.ativo" class="fas fa-check has-text-success"></i>
                    <i v-if="!script.ativo" class="fas fa-times has-error has-text-danger"></i>
                  </span>
                </td>

                <td  align="center">
                  <strong>
                    <span>{{script.descricao}}</span>
                  </strong>
                </td>

                <td align="center">
                  <strong>
                    <span>{{script.ordem}}</span>
                  </strong>
                </td>

                <td align="right">
                  <button  @click="visualizarScript(script)" class="btnVisualizarScript has-margin-right-20 button is-small" aria-haspopup="true" aria-controls="dropdown-menu6">
                    <span class="icon is-small">
                      <i class="fas fa-eye"  aria-hidden="true"></i>
                    </span>
                  </button>

                  <div class="dropdown is-right is-hoverable">
                    <div class="dropdown-trigger">
                      <button class="button  is-small" aria-haspopup="true" aria-controls="dropdown-menu6">
                        <span class="icon is-small">
                          <i class="fas fa-ellipsis-v" aria-hidden="true"></i>
                        </span>
                      </button>
                    </div>
                    <div class="dropdown-menu" id="dropdown-menu6" role="menu">
                      <div class="dropdown-content">
                        <a @click="abrirCadastro(script)" class="btnAbrirEdicao dropdown-item">
                          Editar
                        </a>
                        <a @click="excluir(script);" class="btnExcluir dropdown-item has-text-danger">
                          <strong v-if="script.ativo">Desativar</strong>
                          <strong v-if="!script.ativo">Ativar</strong>
                        </a>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="!versaoSelecionada" style="margin:100px">
            <div class="columns is-centered">
              <span>
                Nenhuma versão selecionada. Selecione uma versão para mostrar seus scripts.
              </span>
            </div>
          </div>
          <div v-if="versaoSelecionada && scripts.length === 0" style="margin:70px">
            <div class="columns is-centered">
              <span>
                Nenhum script encontrado para essa versão, deseja cadastrar um novo?
              </span>
            </div>
            <div class="columns is-centered">
                <button class="button is-big is-link cor-fundo-btn" style="margin:10px" @click="abrirCadastro()">Novo Script</button>
            </div>
          </div>
        </div>
      </div>

      <modal-padrao :titulo="'Informações do Script'" :class="showModalScript" @modalFechado="showModalScript = ''">
            <p><strong>Id: </strong>{{scriptSelecionado.id}}</p>
            <p><strong>Descricao: </strong>{{scriptSelecionado.descricao}}</p>
            <p class="btnFormatarData"><strong>Data de criação: </strong>{{formatarData(scriptSelecionado.dataCriacao)}}</p>
            <p v-if="scriptSelecionado.alteradoPor"><strong>Alterador por: </strong>{{scriptSelecionado.alteradPor}}</p>
            <p v-if="scriptSelecionado.dataAlteração" class="btnFormatarData"><strong>Data da última alteração: </strong>{{formatarData(scriptSelecionado.dataCriacao)}}</p>
            <hr>
            <p><strong>Script</strong></p>
            <code-mirror-script v-if="showModalScript" :script="scriptSelecionado.script">
            </code-mirror-script>
      </modal-padrao>

    </div>
    <modal-confirmacao :class="showModal" :mensagem="'Tem certeza que deseja '+ mensagemConfirmacao + 'a versão ' + scriptSelecionado.descricao + '?'" @modalFechado="modalConfirmarExclusao">
    </modal-confirmacao>
  </div>

</template>

<script>
  import ModalConfirmacao from '../components/ModalConfirmacao.vue';
  import ModalPadrao from '../components/ModalPadrao.vue';
  import CodeMirrorScript from '../components/CodeMirrorScript.vue';
  
  export default {

    components: {
      ModalConfirmacao,
      ModalPadrao,
      CodeMirrorScript,
    },
    created() {
      this.$store.dispatch("produtos/listarTodos");
    },
    data: () => ({
      showModalScript: '',
      showModal: '',
      confirmarExclusao: false,
      scriptSelecionado: {},
      mensagemConfirmacao: '',
      versaoSelecionada: '',
      idProdutoSelecionado: ''
    }),
    watch: {
      scriptSelecionado: {
        handler() {
          this.mensagemConfirmacao = (this.scriptSelecionado.ativo ? 'desativar ' : 'ativar ')
        }
      }
    },
    computed: {
      produtos() {
        return this.$store.state.produtos.items;
      },
      versoes() {
        return this.$store.state.versoes.items;
      },
      carregando() {
        return this.$store.state.scripts.status.carregandoTodos;
      },
      scripts() {
        return this.$store.state.scripts.items;
      },
    },
    methods: {
      formatarData(data){
        if (data) {
          var ano = data.split("-")[0]
          var mes = data.split("-")[1]
          var dia = data.split("-")[2].split("T", 1)
          var hora = data.split("T")[1].split(":", 1)
          var minuto = data.split(":")[1]

          return dia + "/" + mes + "/" + ano + " às " + hora + ":" + minuto
        }
      },
      visualizarScript(payload){
        this.showModalScript = 'is-active';
        this.scriptSelecionado = payload;
      },
      atualizarLista() {
        if (this.versaoSelecionada.id) {
          this.$store.dispatch("scripts/listarTodosPorVersao", this.versaoSelecionada.id) }
      },
      abrirCadastro(script) {
        let payload = {
          componente: 'FormInserirScript',
          dados: {
                  scriptSelecionado:script,
                  produto:this.idProdutoSelecionado,
                  versao:this.versaoSelecionada.id,
                }
        }
          this.$emit('abrirFormInserir', payload)
      },
      excluir(script) {
        this.scriptSelecionado = script
        this.modalConfirmarExclusao();
      },
      preencherListaVersoes(){
        this.versaoSelecionada=''
        this.$store.dispatch("versoes/listarTodosPorProduto", this.idProdutoSelecionado);
      },
      modalConfirmarExclusao(payload) {
        if (this.showModal === '') {
          this.showModal = 'is-active';
        } else {
          this.confirmarExclusao = payload
          this.showModal = '';

          if (this.confirmarExclusao) {
            this.scriptSelecionado = {...this.scriptSelecionado, ativo:!this.scriptSelecionado.ativo}
            this.$store.dispatch("scripts/editarScript",this.scriptSelecionado)
            setTimeout(() => {
                  this.atualizarLista()
                }, 2000)
          }
        }
      }
    },
  };
</script>

<style scoped lang="scss">
@import url(https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.61.1/codemirror.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.61.1/theme/dracula.min.css);

  .btn-atualizar-Lista {
      padding-top: 23px;
  }
  .editar:hover .icon.has-text-white {
    color: #b5b5b5 !important;
  }

  .dd-edicao .dropdown-menu {
    width: 22em;
  }
</style>