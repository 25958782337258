<template>
    <div>
        <!-- <p>{{abc}}</p> -->
        <span class="pctg">{{progresso}}%</span>
        <progress class="progress is-primary" :value=progresso max="100"></progress>

        <div class="columns">
            <div class="column is-pulled-left">
                <button class="button is-link" @click=subtrair(1)>-1</button>
                <button class="button is-link" @click=subtrair(2)>-2</button>
                <button class="button is-link" @click=subtrair(5)>-5</button>
                <button class="button is-link" @click=subtrair(10)>-10</button>
            </div>

            <div class="column is-centered">
                <button class="button is-danger" @click=automatico>Automático</button>
            </div>

            <div class="column is-pulled-right">
                <button class="button is-link" @click=somar(1)>+1</button>
                <button class="button is-link" @click=somar(2)>+2</button>
                <button class="button is-link" @click=somar(5)>+5</button>
                <button class="button is-link" @click=somar(10)>+10</button>
            </div>

        </div>

        <ChartJS
            :idComponente="'8'"
            :tipo="'bar'"
            :colunas="['progresso','teste']"
            :valores="[progresso, 5,0]"
        >
        </ChartJS>
    </div>
</template>

<script>
  import ChartJS from '../components/ChartJS.vue';

export default {
    components: {
        ChartJS
    },
    data() {
        return {
            progresso:1,
            abc:'não caiu no else',
        }
    },
    methods: {
        somar(i){
            this.progresso += i
        },
        subtrair(i){
            this.progresso -= i
        },
        automatico(){
            var id = setInterval(()=>{
                this.progresso++
                if(this.progresso >= 100){
                        clearInterval(id)
                }
            },100)
        },
    }
};
</script>

<style>

    .pctg{
        position: absolute;

        left: 50%;
        font-size: 12px;

    }
</style>
