import {versaoServico} from '../services'

const versoes = {
    namespaced: true,
    state: {
        items: [],
        status: {
            carregandoTodos: false
        },
        versoes: [''],
        componente: ''
    },
    actions: {
        listarTodos({commit, dispatch}) {
            versaoServico.listarTodos(commit, dispatch)
        },

        listarTodosPorProduto({commit, dispatch}, payload) {
            versaoServico.listarTodosPorProduto(commit, dispatch, payload)
        },

        criar({rootState, commit, dispatch}, payload) {
            versaoServico.criar(rootState, commit, dispatch, payload)
        },

        editar({rootState, commit, dispatch}, payload) {
            versaoServico.editar(rootState, commit, dispatch, payload)
        },

        desativar({rootState, commit, dispatch}, payload) {
            versaoServico.desativar(rootState, commit, dispatch, payload)
        },

    },
    mutations: {
        listandoTodos(state) {
            state.status.carregandoTodos = true;
            state.items = []
        },
        listarTodosSucesso(state, versao) {
            state.status.carregandoTodos = false;
            state.items = versao;
        },
        listarTodosErro(state) {
            state.status.carregandoTodos = false;
            state.items = []
        },
    }
}

export default versoes