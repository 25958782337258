<template>
  <div class="columns">
    <div class="column is-12">
      <div class="card">
        <div class="card-content">
          <div class="columns">
            <div class="column">
              <h2 class="title is-4">Produtos</h2>
            </div>
            <div class="column has-text-right">
              <button class="button is-small is-link cor-fundo-btn btnAbrirCadastro" @click="abrirCadastro()">Novo Produto</button>
            </div>
          </div>
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>Nome</th>
                <th align="center">Ativo</th>
                <th align="center">Multitenant</th>
                <th align="center">Schema Padrão</th>
                <th align="center">Versões</th>
                <th align="center">Databases</th>
                <th align="right"></th>
              </tr>
            </thead>
            <tbody v-if="!carregando && produtos.length > 0">
              <tr v-for="(produto , i) in produtos" v-bind:key="i">
                <td>
                  <strong class="has-text-dark">
                    {{ produto.nome }}
                  </strong>
                  <br />
                  <small class="is-size-7 has-text-grey-light">
                    {{ produto.responsavel }}
                  </small>

                </td>
                <td align="center">
                  <span class="icon">
                    <i v-if="produto.ativo" class="fas fa-check has-text-success"></i>
                    <i v-if="!produto.ativo" class="fas fa-times has-error has-text-danger"></i>
                  </span>
                </td>

                <td align="center">
                  <span class="icon">
                    <i v-if="produto.multiTenant" class="fas fa-check has-text-success"></i>
                    <i v-if="!produto.multiTenant" class="fas fa-times has-error has-text-danger"></i>
                  </span>
                </td>
                <td align="center">{{ produto.schemaPadrao }}</td>
                <td align="center">{{ produto.versoes ? produto.versoes.length : 0 }}</td>
                <td align="center">
                  <div class="dropdown is-right is-hoverable">
                    <div class="dropdown-trigger">
                      <span aria-haspopup="true" aria-controls="dropdown-menu">
                        <i class="fas fa-database" aria-hidden="true"></i>
                      </span>
                    </div>
                    <div class="dropdown-menu" id="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <table class="table">
                          <tbody>
                            <tr style="white-space: nowrap" v-for="(database, i) in produto.databases" v-bind:key="i" class=" cor-fundo-btn">
                              <td >{{ database.url }}</td>
                              <td><i title="Visualizar Database" @click="abrirModalEditarVisualizarDb(database, 'Visualizar')" class="fas fa-eye iconesDb"></i></td>
                              <!-- <td><i title="Editar Database" @click="abrirModalEditarVisualizarDb(database, 'Editar')" class="fas fa-edit iconesDb"></i></td> -->
                              <!-- <td><i title="Apagar Database" @click="excluirDatabase(database.id)" class="fas fa-trash iconesDb"></i></td> -->
                              <modal-confirmacao style="color:#4a4a4a" :class="apagarDb" :mensagem="'Tem certeza que deseja excluir esse database?'" @modalFechado="confirmarExclusaoDb">
                              </modal-confirmacao>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </td>
                <td align="center">
                  <div class="dropdown is-right is-hoverable">
                    <div class="dropdown-trigger">
                      <button class="button  is-small" aria-haspopup="true" aria-controls="dropdown-menu6">
                        <span class="icon is-small">
                          <i class="fas fa-ellipsis-v" aria-hidden="true"></i>
                        </span>
                      </button>
                    </div>
                    <div class="dropdown-menu" id="dropdown-menu6" role="menu" style="">
                      <div class="dropdown-content">
                        <a @click="abrirModalCriarDb(produto.id)" class="btnabrirModalDb dropdown-item">
                          Adicionar Database
                        </a>
                        <a @click="abrirEdicao(produto)" class="btnAbrirEdicao dropdown-item">
                          Editar
                        </a>
                        <a @click="excluir(produto);" class="btnExcluir dropdown-item has-text-danger">
                          <strong v-if="produto.ativo">Desativar</strong>
                          <strong v-if="!produto.ativo">Ativar</strong>
                        </a>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-if="!carregando && produtos.length == 0">
              <tr>
                <td colspan="5" class="has-text-centered has-padding-20">
                  <h3>
                    <strong class="has-text-info">Nenhum produto encontrado</strong>
                  </h3>
                </td>
              </tr>
            </tbody>
            <tbody v-if="carregando">
              <tr>
                <td colspan="5" class="has-text-centered has-padding-20">
                  <h3>
                    <strong class="has-text-warning">Carregando Produtos...</strong>
                  </h3>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <modal-padrao :titulo=" this.tipoModalDb + ' Database'" :class="showModalDatabase" @modalFechado="showModalDatabase = ''">
      <div class="columns is-vcentered">
        <div class="column">
          <label><b>URL</b></label>
          <ValidationProvider name="url" rules="required">
            <div slot-scope="{ errors }">
              <input
                :disabled="tipoModalDb === 'Visualizar'"
                v-model="database.url"
                class="input"
              />
              <small class="has-text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
        </div>
        <div class="column" v-if="this.tipoModalDb === 'Adicionar'">
          <label><b>Usuário</b></label>
          <ValidationProvider name="usuario" rules="required">
            <div slot-scope="{ errors }">
              <input
                v-model="database.usuario"
                class="input"
              />
              <small class="has-text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
        </div>
        <div class="column" v-if="this.tipoModalDb === 'Adicionar'">
          <label><b>Senha</b></label>
          <ValidationProvider name="senha" rules="required">
            <div slot-scope="{ errors }">
              <p class="control has-icons-left">
                <input
                  v-model="database.senha"
                  class="input"
                  type="password"
                  required=true
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-lock"></i>
                </span>
              </p>

              <small class="has-text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <div class="columns" style="justify-content: flex-end;">
          <div class="column">
                <input
                  :disabled="tipoModalDb == 'Visualizar'"
                  type="checkbox"
                  name="dbPadrao"
                  v-model="database.padrao"
                />
                <label for="dbPadrao" style="margin-left:10px;">Database Padrão</label>
          </div>
          <button class="button is-link cor-fundo-btn mr-3" @click="criarDatabase()" v-if="this.tipoModalDb !== 'Visualizar'">Salvar</button>
      </div>

      <div class="columns has-margin-top-20">
        <div class="column">
          <span class="subtitle">Schemas</span>
          <button
            v-if="this.tipoModalDb === 'Adicionar'"
            type="button"
            @click="addSchema"
            class="btnAddSchema button is-link is-light is-small has-margin-left-10">
            <span class="icon is-small">
              <i class="fas fa-plus"></i>
            </span>
          </button>
        </div>
      </div>
      <div v-for="(schema, index) in schemas" v-bind:key="index">
        <div class="columns" v-if="tipoModalDb === 'Adicionar'">
          <div class="column is-4">
            <label><b>Nome</b></label>
            <input v-model="schema.nome" class="input">
          </div>
          <div class="column" style="margin: auto">
            <button
              class="button is-link is-light is-small"
              @click="removerSchema(index)">
              <span class="icon is-small">
                <i class="fas fa-trash"></i>
              </span>
            </button>
          </div>
        </div>

        <div v-if="tipoModalDb === 'Visualizar'">
          <p><strong>Schema {{index + 1}}</strong></p>
          <div class="columns">
            <div class="column">
              <p><strong>Id: </strong>{{schema.id}}</p>
            <!-- </div> -->
            <!-- <div class="column"> -->
              <p><strong>Nome: </strong>{{schema.nome}}</p>
            <!-- </div> -->
            <!-- <div class="column"> -->
              <p><strong>Data de criação: </strong>{{formatarData(schema.dataCriacao)}}</p>
            </div>
          </div>
          <hr>
        </div>  
      </div>

     </modal-padrao>
    <modal-confirmacao :class="showModal" :mensagem="'Tem certeza que deseja '+ mensagemConfirmacao + 'o produto ' + produtoSelecionado.nome + '?'" @modalFechado="modalConfirmarExclusao">
    </modal-confirmacao>
  </div>

</template>

<script>
  import ModalConfirmacao from '../components/ModalConfirmacao.vue';
  import ModalPadrao from '../components/ModalPadrao.vue';

  export default {

    components: {
      ModalConfirmacao,
      ModalPadrao
    },
    created() {
      this.$store.dispatch("produtos/listarTodos");
    },
    computed: {
      carregando: function () {
        return this.$store.state.produtos.status.carregandoTodos;
      },
      produtos: function () {
        return this.$store.state.produtos.items;
      },
      getSchemas: function () {
        return this.$store.state.schemas.items;
      },
    },
    data: () => ({
      apagarDb: '',
      dbSelecionado: '',
      database:{},
      schemas:[],
      showModalDatabase:'',
      loading: false,
      showModal: '',
      confirmarExclusao: false,
      produtoSelecionado: {},
      mensagemConfirmacao: '',
      componenteCadastroProduto: 'FormInserirProduto',
      tipoModalDb: ''
    }),
    watch: {
      produtoSelecionado: {
        handler() {
          this.mensagemConfirmacao = (this.produtoSelecionado.ativo ? 'desativar ' : 'ativar ')
        }
      },
    },
    methods: {
      formatarData(data){
          var ano = data.split("-")[0]
          var mes = data.split("-")[1]
          var dia = data.split("-")[2].split("T", 1)
          var hora = data.split("T")[1].split(":", 1)
          var minuto = data.split(":")[1]

          return dia + "/" + mes + "/" + ano + " às " + hora + ":" + minuto
      },
      addSchema() {
        this.schemas.push({
            nome: "",
            idDatabase: "",
        });
      },

      removerSchema(index) {
          this.schemas.splice(index, 1);
      },

      abrirModalCriarDb(produto){
        this.tipoModalDb="Adicionar"
        this.database={}
        this.schemas=[]
        this.database.idProduto = produto
        this.showModalDatabase = 'is-active'
      },
      async abrirModalEditarVisualizarDb(database, tipo){
        this.tipoModalDb = tipo
        this.database = database
        if(this.tipoModalDb === 'Visualizar'){
          await this.$store.dispatch('buscarSchemaPorDb',this.database.id).then(()=>{
            this.schemas = this.getSchemas
          })
        }
        this.showModalDatabase = 'is-active'
      },
      excluirDatabase(database){
        this.apagarDb = 'is-active';
        this.dbSelecionado = database
      },
      criarDatabase(){
        this.$store.dispatch('criarDatabase', this.database).then((response)=>{
          if(this.schemas.length > 0){
            for (let i = 0; i < this.schemas.length; i++) {
              this.schemas[i].idDatabase = response.data.id
              this.$store.dispatch('criarSchema', this.schemas[i])
            }
          }
          this.showModalDatabase=''
          this.database={}
          this.schemas=[]
          this.$store.dispatch("produtos/listarTodos")
        })
      },
      abrirCadastro() {
        let payload = {
          componente: 'FormInserirProduto'
        }
          this.$emit('abrirFormInserir', payload)
      },
      abrirEdicao(produtoSelecionado) {
        let payload = {
          componente: 'FormInserirProduto',
          dados: produtoSelecionado
        }
        this.$emit('abrirFormInserir', payload)
      },
      excluir(produto) {
        this.produtoSelecionado = produto
        this.modalConfirmarExclusao();
      },
      modalConfirmarExclusao(payload) {

        if (this.showModal === '') {
          this.showModal = 'is-active';
        } else {
          this.confirmarExclusao = payload
          this.showModal = '';

        if (this.confirmarExclusao) {
          this.produtoSelecionado = {...this.produtoSelecionado, ativo:!this.produtoSelecionado.ativo}
          this.$store.dispatch("produtos/editar",this.produtoSelecionado)
          setTimeout(() => {
            this.$store.dispatch("produtos/listarTodos")
          },2000)
        }
        }
      },
      confirmarExclusaoDb(payload) {
          this.confirmarExclusao = payload
          this.apagarDb = '';

        if (this.confirmarExclusao) {
          this.$store.dispatch('excluirDatabase', this.dbSelecionado).then(()=>{
          this.$store.dispatch("produtos/listarTodos")
          }
          )
        }
      }
    }
  };
</script>

<style scoped>
  .editar:hover .icon.has-text-white {
    color: #b5b5b5 !important;
  }

  .iconesDb:hover{
    transform: scale(1.1);
    opacity: 0.8;
  }

  .dd-edicao .dropdown-menu {
    width: 22em;
  }
</style>