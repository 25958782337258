<template>
  <div id="app">
    <script type="module" src="https://cdn-erpforme.alterdata.com.br/nimbus/atena/mf/atena-utils/atena-utils.min.js"/>
    <div class="column is-12 is-pulled-left is-paddingless">
      <div class="column is-2 is-pulled-left">
        <aside class="menu menu-backgroud">
          <p class="menu-label">Funcionalidades</p>
          <ul class="menu-list">
            <li>
              <a @click="componenteSelecionado('ListarProdutos')">Produtos</a>
            </li>
            <li>
              <a @click="componenteSelecionado('ListarVersoes')">Versões</a>
            </li>
            <li>
              <a @click="componenteSelecionado('ListarScripts')">Scripts</a>
            </li>
            <!-- <li>
              <a class="menu-pai">Migração</a>
              <ul>
                <li>
                  <a @click="componenteSelecionado('FormExecutarMigracao')">Executar</a>
                </li>
                <li>
                  <a @click="componenteSelecionado('FormAcompanharMigracao')">Acompanhar</a>
                </li>
                <li>
                  <a @click="componenteSelecionado('FormRefazerMigracao')">Refazer</a>
                </li>
              </ul>
            </li>
            <li>
              <a class="menu-pai">Bases</a>
              <ul>
                <li>
                  <a @click="componenteSelecionado('FormInicializarBase')">Inicialização</a>
                </li>
                <li>
                  <a @click="componenteSelecionado('FormReinicializarBase')">Reinicialização</a>
                </li>
              </ul>
            </li> -->
          </ul>
        </aside>
      </div>

      <div class="column is-10 is-pulled-left">
        <component
          @abrirView = "componente=$event"
          @abrirFormInserir = "abrirEdicao($event)"
          v-bind:is="componente"
          :dados ="produtoSelecionado"
        >
        </component>
      </div>


      <!-- <notificacao v-if="showNotification"/> -->
      <atena-utils-mensagens-retorno
        :parametros-mensagem='parametrosMensagem'
        :tipo-crud='tipoCrud'
        v-if="showNotification"/>
      <atena-utils-modal-loading v-if='dadosModal.exibirModal'/>

    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueResource from 'vue-resource';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, numeric } from "vee-validate/dist/rules";
import axios from 'axios'
import store from "@/store";


import Apresentacao from "@/views/Apresentacao.vue";
import ListarProdutos from "@/views/ListarProdutos.vue";
import ListarVersoes from "@/views/ListarVersoes.vue";
import ListarScripts from "@/views/ListarScripts.vue";
import FormInserirProduto from "@/views/FormInserirProduto.vue";
import FormInserirVersao from "@/views/FormInserirVersao.vue";
import FormInserirScript from "@/views/FormInserirScript.vue";
import FormExecutarMigracao from "@/views/FormExecutarMigracao.vue";
import FormRefazerMigracao from "@/views/FormRefazerMigracao.vue";
import FormAcompanharMigracao from "@/views/FormAcompanharMigracao.vue";
import FormReinicializarBase from "@/views/FormReinicializarBase.vue";
import FormInicializarBase from "@/views/FormInicializarBase.vue";
import ChartJS from "@/components/ChartJS.vue";
import Notificacao from './components/Notificacao.vue';
import Teste from './views/TesteBar.vue';

extend('numeric', {
  ...numeric,
  message: "Campo {_field_} deve ser númerico"
});
extend("required", {
  ...required,
  message: "Campo {_field_} obrigatório."
});

Vue.prototype.$http = axios;
Vue.use(VueResource);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.config.productionTip = false;

export default {
  store,
  components: {
    Apresentacao,
    ListarProdutos,
    ListarVersoes,
    ListarScripts,
    FormInserirProduto,
    FormInserirVersao,
    FormInserirScript,
    FormExecutarMigracao,
    FormRefazerMigracao,
    FormAcompanharMigracao,
    FormReinicializarBase,
    FormInicializarBase,
    Notificacao,
    Teste,
    ChartJS
  },
  data() {
    return {
      componente: 'Apresentacao',
      produtoSelecionado: null
    }
  },
  computed: {
    showNotification() {
      return store.state.notificacoes.exibirMensagem;
    },
    dadosModal() {
      return store.state.dadosModal;
    },
    parametrosMensagem() {
      let mensagem = JSON.stringify(store.state.notificacoes)
      return mensagem
    },
    tipoCrud() {
      return store.state.tipoCrud;
    }
  },
  methods: {
    componenteSelecionado(componente) {
      this.componente = componente
    },

    abrirEdicao(payload) {
      this.produtoSelecionado = payload.dados
      this.componente = payload.componente
    },

    criarEventoFecharMensagem() {
      window.addEventListener('fecharMensagemRetorno', (e) => {
        store.commit('fecharMensagem', e.detail)
      })
    }
  },
  created() {
    this.criarEventoFecharMensagem()
  }
};

</script>

<style lang="scss">
@import url("https://cdnjs.cloudflare.com/ajax/libs/bulma/0.9.2/css/bulma.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/bulma-helpers/0.4.0/css/bulma-helpers.min.css");
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '@/comum/styles/main.scss';

.modal-load {
  height: 200px;
  width: 550px !important;
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  opacity: 10;
  z-index: -1;
  transition: opacity .3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  .loader {
    height: 180px;
    width: 180px;
  }

  &.is-active {
    opacity: 1;
    z-index: 1;
  }
}
  span {
    user-select: none;
    -webkit-user-select:none;
  }
</style>

