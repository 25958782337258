import {scriptServico} from '../services'

const scripts = {
    namespaced: true,
    state: {
        items: [],
        status: {
            carregandoTodos: false
        },
        scripts: [''],
        codigo:'',
        componente: ''
    },
    actions: {
        listarTodos({commit, dispatch}) {
            scriptServico.listarTodos(commit, dispatch)},

        listarTodosPorVersao({commit, dispatch}, payload) {
            scriptServico.listarTodosPorVersao(commit, dispatch, payload)},

        criarScript({rootState, commit, dispatch}, payload) {
            scriptServico.criar(rootState,commit, dispatch, payload)},

        editarScript({rootState, commit, dispatch}, payload) {
            scriptServico.editar(rootState,commit, dispatch, payload)},
    },
    mutations: {
        listandoTodos(state) {
            state.status.carregandoTodos = true;
            state.items = []
        },
        listarTodosSucesso(state, scripts) {
            state.status.carregandoTodos = false;
            state.items = scripts;
        },
        listarTodosErro(state) {
            state.status.carregandoTodos = false;
            state.items = []
        },
        alterarScript(state, payload) {
            state.codigo = payload;
        },
    }
}

export default scripts