export default class NovoScript {

    constructor(objeto) {
        this.ativo = true,
        this.descricao = '',
        this.idVersao = '',
        this.ordem = null,
        this.script = ''
        Object.assign(this, objeto);
    }
}