const notificacoes = {
    state: {
        exibirMensagem: false,
        tipoMensagem: "sucesso",
        textoMensagem: "",
    },
    mutations: {
        exibirNotification(state, payload) {
            window.scrollTo(0,0)
            state.exibirMensagem = true
            state.tipoMensagem = typeof payload === 'string' ? payload : "erro"
            if (state.tipoMensagem === "erro") {
                state.textoMensagem = payload.message ? payload.message : payload
            }
        },
        fecharMensagem(state, payload) {
            state.exibirMensagem = payload
        }
    },
    getters: {
        exibirMensagem: state => state.exibirMensagem,
        tipoMensagem: state => state.tipoMensagem,
        textoMensagem: state => state.textoMensagem
    },
}

export default notificacoes