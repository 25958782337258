import axios from 'axios'
import config from 'config'
import {authHeader} from '../helpers'

export const schemaServico = {
    criar,
    buscarPorDb
  }

  function criar(rootState, commit, dispatch, schema) {
    let dadosModal = {
      exibirModal: true,
      textoModal: 'Realizando Consulta'
    }
    commit("exibirOcultarModalLoading", dadosModal, {root:true})

    const requestOptions = {
      method: 'POST',
      data: JSON.stringify(schema),
      headers: authHeader(),
      transformRequest: [function (data) {
        return data;
      }]
    }
  
    return axios(`${config.apiUrl}/schemas`, requestOptions)
      .then((response) => {
        rootState.tipoCrud = response.config.method
        commit("exibirNotification", "sucesso", {root:true})
        return response
      })
      .catch((error) => {
        commit("exibirNotification", error, {root:true})
        return error
      })
      .finally(() => {
        commit('exibirOcultarModalLoading', false, {root:true})
      })
  }

  function buscarPorDb(commit, dispatch, database) {
    let dadosModal = {
      exibirModal: true,
      textoModal: 'Realizando Consulta'
    }
    commit("exibirOcultarModalLoading", dadosModal, {root:true})

    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
      transformRequest: [function (data) {
        return data;
      }]
    }

    return axios(`${config.apiUrl}/schemas/por-database/${database}`, requestOptions)
      .then((response) => {
        commit('buscarSchemaPorDbSucesso', response.data)
        return response
      })
      .catch((error) => {
        commit('buscarSchemaPorDbErro')
        commit("exibirNotification", error, {root:true})
        return error
      })
      .finally(() => {
        commit('exibirOcultarModalLoading', false, {root:true})
      })
  }
