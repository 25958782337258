export function formatResponse(response) {
    return response.data
}

export function formatResponseError(errorResponse) {
    let data = errorResponse.response.data
    let error = {
        title: data.title === '' ? 'Ocorreu um erro ao executar sua ação:' : data.title,
        status: errorResponse.response.status,
        type: data.type === null ? 'error' : data.type,
        detail: data.detail === '' ? 'Estamos com problemas para executar sua ação. Tente novamente mais tarde' : data.detail,
        code: data.code
    }
    if (data.messages) {
        let msg = ''
        data.messages.forEach(function (item) {
            msg = msg + item.detail + '<br>'
        })
        error.detail = msg
    }
    return Promise.reject(error)
}

export function forceFileDownload(response, nomeArquivo) {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', nomeArquivo)
    document.body.appendChild(link)
    link.click()
}