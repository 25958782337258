<template>
<div>
    <textarea id="codemirror-editor" v-model="codigo"></textarea>
</div>
</template>

<script>
    import * as CodeMirror from 'codemirror'
    import 'codemirror/addon/display/autorefresh'
    import 'codemirror/mode/sql/sql'

    export default {
        props: {
            script: String,
        },
        data() {
            return {
                codigo: this.script
            }
        },
        methods: {
            MontarCodeMirror(process){
                let code = process === 'production' ?
                    document.querySelector('mf-atena-migracoes').shadowRoot.getElementById('codemirror-editor') :
                    document.getElementById('codemirror-editor')

                CodeMirror.fromTextArea(code, {
                    lineNumbers: true,
                    autoRefresh:true,
                    theme: 'dracula',
                    mode: 'sql'
                }).on('change', editor => {
                    this.$store.commit('scripts/alterarScript', editor.getValue())
                });
            },
        },
        mounted() {
            this.MontarCodeMirror(process.env.NODE_ENV)
        },
    };
</script>

<style scoped lang="scss">
  .largura{
    width: 80% !important;
  }
</style>