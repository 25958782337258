import axios from 'axios'
import config from 'config'

import {
  formatResponse,
  formatResponseError,
  authHeader
} from '../helpers'

export const migracoesServico = {
  buscarMigracoes,
  acompanharMigracao
}
function buscarMigracoes(payload) {
  let dadosModal = {
    exibirModal: true,
    textoModal: 'Realizando Consulta'
  }
  this.$store.commit("exibirOcultarModalLoading", dadosModal)

  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return axios.get(`${config.apiUrl}/acompanhamentos/por-produto?idProduto=${payload.idProduto}` + (payload.limit ? `&limit=${payload.limit}` : ''), requestOptions)
    .then(function (response) {
      this.$store.commit('listarAcompanhamentos', response.data)
      return response
    })
    .catch(function (error) {
      this.$store.commit("exibirNotification", error)
        return formatResponseError(error)
    })
    .finally(function() {
      this.$store.commit('exibirOcultarModalLoading', false)
    })
}

function acompanharMigracao(payload) {
  let dadosModal = {
    exibirModal: true,
    textoModal: 'Realizando Consulta'
  }
  this.$store.commit("exibirOcultarModalLoading", dadosModal)

  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return axios.get(`${config.apiUrl}/acompanhamentos/${payload.idMigracao}`, requestOptions)
    .then(function (response) {
      this.$store.commit('listarAcompanhamentos', response.data)
      return response
    })
    .catch(function (error) {
      this.$store.commit("exibirNotification", error)
      return formatResponseError(error)
    })
    .finally(function() {
      this.$store.commit('exibirOcultarModalLoading', false)
    })
}