<template>
  <div class="columns">
    <div class="column">
      <div class="card">
        <div class="card-content">

          <p class="title is-4" v-if="!this.editando">Novo Produto</p>
          <p class="title is-4" v-if="!!this.editando">Editar Produto</p>

          <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(salvar)">
              <div class="columns">
                <div class="column">
                  <div class="field">
                    <label class="label">Nome</label>
                    <div class="control">
                      <ValidationProvider name="nome" rules="required">
                        <div slot-scope="{ errors }">
                          <input
                            v-model="produto.nome"
                            type="text"
                            class="input"
                            :class="{'is-danger' : errors.length}"
                          />
                          <small class="has-text-danger">{{ errors[0] }}</small>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>

                <div class="column">
                  <div class="field">
                    <label class="label">Schema Padrão</label>
                    <div class="control">
                      <input v-model="produto.schemaPadrao" class="input" type="text" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <div class="field">
                    <label class="label">Responsável</label>
                    <div class="control">
                      <ValidationProvider name="responsável" rules="required">
                        <div slot-scope="{ errors }">
                          <input
                            v-model="produto.responsavel"
                            class="input"
                            :class="{'is-danger' : errors.length}"
                            type="text"
                          />
                          <small class="has-text-danger">{{ errors[0] }}</small>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                                <div class="column">
                                    <div class="field">
                <input
                  class="is-checkradio is-link"
                  id="multitenancycheck"
                  type="checkbox"
                  name="multitenancycheck"
                  checked="checked"
                  v-model="produto.multiTenant"
                />
                <label for="multitenancycheck" style="margin-left:10px;">Multitenancy</label>
              </div>
                </div>

              </div>

              <div class="is-divider has-margin-top-50"></div>

              <footer class="field is-grouped is-grouped-right has-margin-bottom-5">
                <p class="control">
                  <button class="button is-link cor-fundo-btn" :class="{'is-loading' : loading }">
                    <span>Salvar</span>
                    <span class="icon is-small">
                      <i class="fas fa-save"></i>
                    </span>
                  </button>
                </p>
              </footer>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import NovoProduto from '@/model/produto/novoProduto.js'

export default {
  props: {
    dados: {
      type: Object
      }
  },
  created() {
    this.preencherInputs();
  },
  data() {
    return {
      loading: false,
      databases: [],
      produto: new NovoProduto(),
      submitted: false,
      resposta: {},
      id: 1,
      editando: false
    };
  },
  methods: {
    salvar() {
      this.loading = true;

      if (!!this.editando) {
        this.$store.dispatch("produtos/editar", this.produto);
      } else {
        this.$store.dispatch("produtos/criar", this.produto);
      }

      this.loading = false;
      this.limparTela()
    },
    preencherInputs() {
      this.editando = (!!this.dados)
      if (!!this.editando) {
        this.produto = this.dados
      }else{
        this.produto = new NovoProduto();
      }
    },
    limparTela() {
      this.editando = false;
      this.produto = new NovoProduto();
      this.$refs.form.reset()
    },
  }
};
</script>

<style scoped></style>