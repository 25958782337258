<template>
  <div class="columns">
    <div class="column is-12">
      <div class="card">
        <div class="card-content">
          <p class="title is-4">Nova Versão</p>
          <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <form class="salvar" @submit.prevent="handleSubmit(salvar)">
              <div class="columns">
                <div class="column">
                    <label class="label">Id do Produto</label>
                    <div class="select is-fullwidth">
                      <select
                      :disabled="true"
                        v-model="versao.idProduto">
                        <option v-for="(produto, i) in produtos" v-bind:key="i" :value="produto.id">{{produto.nome}}</option>
                      </select>
                    </div>
                </div>
                <div class="column">
                  <div class="field">
                    <label class="label">Código da Versão</label>
                    <div class="control">
                      <ValidationProvider name="versão" rules="required">
                        <div slot-scope="{ errors }">
                          <input
                            v-model="versao.codigo"
                            type="text"
                            class="input"
                            :class="{'is-danger' : errors.length}"
                          />
                          <small class="has-text-danger">{{ errors[0] }}</small>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <div class="field">
                    <label class="label">Descrição</label>
                    <div class="control">
                      <ValidationProvider name="descrição" rules="required">
                        <div slot-scope="{ errors }">
                          <input
                            v-model="versao.descricao"
                            type="text"
                            class="input"
                            :class="{'is-danger' : errors.length}"
                          />
                          <small class="has-text-danger">{{ errors[0] }}</small>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="columns">
                <div class="column is-12">
                  <div class="field">
                    <label class="label">Script</label>
                    <div class="control">
                      <ValidationProvider name="script" rules="required">
                        <div slot-scope="{ errors }">
                          <codemirror v-model="versao.script" :options="cmOption"></codemirror>
                          <small class="has-text-danger">{{ errors[0] }}</small>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
              <div class="is-divider has-margin-top-10"></div> -->

              <footer class="field is-grouped is-grouped-right has-margin-bottom-5">
                <p class="control">
                  <button class="button is-link cor-fundo-btn" :class="{'is-loading' : loading }">
                    <span>Salvar</span>
                    <span class="icon is-small">
                      <i class="fas fa-save"></i>
                    </span>
                  </button>
                </p>
              </footer>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import "codemirror/lib/codemirror.css";
// import "codemirror/keymap/sublime.js";
// import "codemirror/theme/base16-light.css";
// import "codemirror/mode/sql/sql.js";
// import "codemirror/theme/base16-dark.css";
// import "codemirror/addon/selection/active-line.js";
// import "codemirror/keymap/sublime.js";
// import "codemirror/addon/fold/foldgutter.css";

export default {
  props: {
    dados: {
      type: Object
      }
  },
  data() {
    return {
      resposta: {},
      loading: false,
      versao: {},
      editando: false,
      cmOption: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        foldGutter: true,
        styleSelectedText: true,
        mode: "text/x-sql",
        keyMap: "sublime",
        matchBrackets: true,
        showCursorWhenSelecting: true,
        theme: "base16-light",
        extraKeys: { Ctrl: "autocomplete" },
        hintOptions: {
          completeSingle: false
        }
      }
    };
  },
  created() {
    this.$store.dispatch("produtos/listarTodos");
    this.preencherInputs();
  },
  computed: {
    produtos() {
      return this.$store.state.produtos.items;
    },
  },
  methods: {
    salvar() {
      this.loading = true;

      if (!!this.editando) {
        this.$store.dispatch("versoes/editar", this.versao)
      } else {
        this.$store.dispatch("versoes/criar", this.versao)
      }

      this.loading = false;
      this.limparTela()
      this.$emit('abrirView', 'ListarVersoes')
    },
    preencherInputs() {
      this.editando = (!!this.dados.id)

      if (!!this.editando) {
        this.versao.ativo = this.dados.ativo
        this.versao.codigo = this.dados.codigo
        this.versao.descricao = this.dados.descricao
        this.versao.id = this.dados.id
      }else{
        this.versao = {}
        this.versao.ativo = true
      }

      this.versao.idProduto = this.dados.produto.id
    },
    limparTela() {
      this.editando = false;
      this.versao = {};
      // this.$refs.form.reset();
    },
  }
};
</script>

<style lang="scss" scoped>
</style>
