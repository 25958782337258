import axios from 'axios'
import eventBus from '@/eventBus'
import config from 'config'
import {authHeader} from '../helpers'

export const databaseServico = {
  criar,
  excluir
}

function criar(rootState, commit, dispatch, database) {
  let dadosModal = {
    exibirModal: true,
    textoModal: 'Realizando Consulta'
  }
  commit("exibirOcultarModalLoading", dadosModal, {root:true})

  const requestOptions = {
    method: 'POST',
    data: JSON.stringify(database),
    headers: authHeader(),
    transformRequest: [function (data) {
      return data;
    }]
  }

  return axios(`${config.apiUrl}/databases`, requestOptions)
    .then((response) => {
      rootState.tipoCrud = response.config.method
      commit("exibirNotification", "sucesso", {root:true})
      return response
    })
    .catch((error) => {
      commit("exibirNotification", error, {root:true})
      return error
    })
    .finally(() => {
      commit('exibirOcultarModalLoading', false, {root:true})
    })
}

function excluir(rootState, commit, dispatch, payload) {
  let dadosModal = {
    exibirModal: true,
    textoModal: 'Realizando Consulta'
  }
  commit("exibirOcultarModalLoading", dadosModal, {root:true})

  const requestOptions = {
      method: 'delete',
      headers: authHeader(),
      transformRequest: [function (data) {
          return data;
      }]
  }

  return axios(`${config.apiUrl}/databases/${payload}`, requestOptions)
  .then((response) => {
    rootState.tipoCrud = response.config.method
    commit("exibirNotification", "sucesso", {root:true})
    return response
  })
  .catch((error) => {
    commit("exibirNotification", error, {root:true})
    return error
  })
  .finally(() => {
    commit('exibirOcultarModalLoading', false, {root:true})
  })
}